/*
* 共通JS
*/

$(function() {

  // ハンバーガメニュー開閉
  $(".spMenu").on("click", function() {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $.when(
        $("nav").removeClass("open").addClass("close")
      )
      .done(function() {
//        $("nav").css({'display':'none'});
      });
    } else {
      $(this).addClass("active");
      $.when(
//        $("nav").css({'display':'block'})
      )
      .done (function() {
        $("nav").addClass("open").removeClass("close");
      });
    }
  });

  // メニュークリック時にnavを閉じる
  $('nav a[href]').on('click', function(event) {
    $('.spMenu').click();
  });

  /*
  * カテゴリツリー
  */
  $('.rank').on('click', function(event) {
    event.preventDefault();
    $(this).next('ul').slideToggle();
  });
  var activeCategory = $('.menuCategory .active');
  var activeChild = $(activeCategory).next('ul');
  $(activeChild).show();
  $(activeCategory).parent('ul').show();
  $(activeCategory).parent('li').parent('ul').show();
  $(activeCategory).parent('li').parent('ul').parent('li').parent('ul').show();
  $(activeCategory).parent('li').parent('ul').parent('li').parent('ul').parent('li').parent('ul').show();

  /*
  * データ削除確認
  */
//  $('.formDelete').on('submit', function() {
  $('body').on('submit', '.formDelete', function() {
    if(window.confirm('削除してよろしいですか？')) {
      return true;
    }
    else {
      return false;
    }
  });
  
  /*
  * 画像アップロードプレビュー
  */
  $('.inputFileUpload').on('change', function(e) {
    // プレビューターゲット取得
    var targetImg = $(this).parents('.uploadImage').find('img');
    if (targetImg) {
      let reader = new FileReader();
      reader.onload = function(e) {
        $(targetImg).attr('src', e.target.result);
      }
      reader.readAsDataURL(e.target.files[0]);
    }
    // 削除フラグクリア
    var targetDeleteFlag = $(this).parents('.uploadImage').find('.uploadStatus');
    $(targetDeleteFlag).val('');
  });
  /*
  * アップロード選択クリア
  */
  $('.inputFileClear').on('click', function() {
    // file inputbox取得
    var targetInput = $(this).parents('.uploadImage').find('input[type=file]');
    $(targetInput).val('');
    // プレビュー画像クリア
    var targetImg = $(this).parents('.uploadImage').find('img');
    $(targetImg).attr('src', '');
    // 削除フラグクリア
    var targetDeleteFlag = $(this).parents('.uploadImage').find('.uploadStatus');
    $(targetDeleteFlag).val('');
  });
  /*
  * アップロード画像を元に戻す（取り消し）
  */
  $('.undoUploadFile').on('click', function() {
    // file inputbox取得
    var targetInput = $(this).parents('.uploadImage').find('input[type=file]');
    // プレビューターゲット取得
    var targetImg = $(this).parents('.uploadImage').find('img');
    // 元のファイル名があるか判定
    if ($(this).is('[regFileName]')) {
      // 元のファイル名がある
      // 元のファイル名取得
      var regFileName = $(this).attr('regFileName');
      // srcセット
      $(targetImg).attr('src', regFileName);
    }
    else {
      // 元のファイル名無し
      $(targetImg).attr('src', '');
    }
    // inputクリア
    $(targetInput).val('');
    // 削除フラグクリア
    var targetDeleteFlag = $(this).parents('.uploadImage').find('.uploadStatus');
    $(targetDeleteFlag).val('');
  });

  /*
  * アップロード画像削除
  */
  $('.deleteUploadFile').on('click', function() {
    // file inputbox取得
    var targetInput = $(this).parents('.uploadImage').find('input[type=file]');
    // アップロードファイルクリア
    $(targetInput).val('');
    // プレビュー画像クリア
    var targetImg = $(this).parents('.uploadImage').find('img');
    $(targetImg).attr('src', '');
    // 削除フラグセット
    var targetDeleteFlag = $(this).parents('.uploadImage').find('.uploadStatus');
    $(targetDeleteFlag).val('delete');
  });

});
